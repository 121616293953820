import $ from 'jquery';

// import Swiper JS
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';


window.addEventListener('load', (event) => {
    console.log('page is fully loaded');
    document.body.style.opacity = "1";
});

$('.header-field').click(function(){
    parent = $(this).parent('.group-fields');
    parent.find('.fields').slideToggle();
    $(this).find('img').toggleClass('close');
});

$('.fileinput').click(function(){
    console.log('click');
    $(this).next().slideDown();
});

$('.cerrar').click(function(){
    $(this).parent().parent().slideUp();
});

$('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
  $(this).toggleClass('open');
  $('.menu-display').toggleClass('active');
  $('header').toggleClass('active');

});

/* SLIDER */

const swiper = new Swiper('.swipper', {
    // Optional parameters
    loop: false,
    spaceBetween: 30,
    speed: 500,
    effect: 'fade',
    autoHeight: true,
    fadeEffect: {
      crossFade: true,
    },
    centeredSlides: true,
    slidesPerView: 1,
    
    // Navigation arrows
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
    },

});

const swiper2 = new Swiper('.slider', {
  // Optional parameters
  loop: true,
  spaceBetween: 30,
  speed: 500,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  centeredSlides: true,
  slidesPerView: 1,
  
  // Navigation arrows
  navigation: {
    nextEl: '.right',
    prevEl: '.left',
  },

});

$('.evolucion-images').click(function(){
  $('.popup-images-evolucion').slideDown();
});

$('.presentacion-images').click(function(){
  $('.popup-images-presentacion').slideDown();
});

$('.close').click(function(){
  $(this).parent().slideUp();
});